//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding component variables.
//  ================================================================================================
#Selected{
    background-color: #8ec3f5;
    border:1px solid #8ec3f5;
}

#Unavailable{
    background-color: rgb(158, 157, 157);
     border:1px solid rgb(158, 157, 157);
}

  // ratings style
  .filled-star {
    fill: $warning;
    stroke: $warning;
    color: $warning;
  }
  .unfilled-star {
    stroke: $gray-100;
    color: $gray-100;
  }

  // InValid Select
.react-select.is-invalid {
  .react-select__control {
    border-color: $danger;
  }
}

// ** table border
// .icmOyL:not(:last-of-type),.lhVPMq:not(:last-of-type){
// border: none !important;
// }


//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

// max-width
.max-w-15 {
    max-width: 15px;
}

.max-w-20 {
    max-width: 20px;
}

.max-w-24 {
    max-width: 24px;
}

.max-w-40 {
    max-width: 40px;
}

.max-w-50 {
    max-width: 50px;
}

.max-w-65 {
    max-width: 65px;
}

.max-w-75 {
    max-width: 75px;
}

.max-w-80 {
    max-width: 80px;
}

.max-w-100 {
    max-width: 100px;
}

.max-w-120 {
    max-width: 120px;
}

.max-w-180 {
    max-width: 180px;
}

.max-w-200 {
    max-width: 200px;
}

.max-w-230 {
    max-width: 230px;
}

.max-w-250 {
    max-width: 250px;
}

.max-w-260 {
    max-width: 260px;
}

.max-w-280 {
    max-width: 280px;
}

.max-w-310 {
    max-width: 310px;
}

.max-w-315 {
    max-width: 315px;
}

.max-w-370 {
    max-width: 370px;
}

.max-w-400 {
    max-width: 400px;
}

.max-w-450 {
    max-width: 450px;
}

.max-w-576 {
    max-width: 576px;
}

.max-w-625 {
    max-width: 625px;
}

.max-w-730 {
    max-width: 730px;
}

.max-w-750 {
    max-width: 750px;
}

.max-w-820 {
    max-width: 820px;
}

.max-w-950 {
    max-width: 950px;
}

.max-w-60 {
    max-width: 60%;
}

// min-width
.min-w-40 {
    min-width: 40px;
}

.min-w-80 {
    min-width: 80px;
}

.min-w-140 {
    min-width: 140px;
}

.min-w-200 {
    min-width: 140px;
}

.min-w-240 {
    min-width: 240px;
}

.min-w-300 {
    min-width: 300px;
}

// max-height

.max-h-80 {
    max-height: 80px;
}

.max-h-180 {
    max-height: 180px;
}

.max-h-200 {
    max-height: 200px;
}

.max-h-300 {
    max-height: 300px;
}

.max-h-400 {
    max-height: 400px;
}

.max-h-500 {
    max-height: 500px;
}

// min-height

.min-h-20 {
    min-height: 20px;
}

.min-h-30 {
    min-height: 30px;
}

.min-h-50 {
    min-height: 50px;
}

.min-h-80 {
    min-height: 80px;
}

.min-h-100 {
    min-height: 100px;
}

.min-h-130 {
    min-height: 130px;
}

.min-h-150 {
    min-height: 150px;
}

.min-h-180 {
    min-height: 180px;
}

.min-h-200 {
    min-height: 200px;
}

.min-h-230 {
    min-height: 230px;
}

.min-h-300 {
    min-height: 300px;
}

.min-h-400 {
    min-height: 400px;
}

.min-h-500 {
    min-height: 500px;
}

// font weight
.bold {
    font-weight: 600;
}

.semibold {
    font-weight: 500;
}

// white-space-nowrap
.white-space-nowrap {
    white-space: nowrap;
}

.selectbox {
    padding: 11px 25px !important;
}

// Width
.w-12 {
    width: 12px;
}

.w-14 {
    width: 14px;
}

.w-18 {
    width: 18px;
}

.w-20 {
    width: 20px;
}

.w-24 {
    width: 24px;
}

.w-28 {
    width: 28px;
}

.w-30 {
    width: 30px;
}

.w-40 {
    width: 40px;
}

.w-45 {
    width: 45px;
}

.w50 {
    width: 50px;
}

.w-65 {
    width: 65px;
}

.w-70 {
    width: 70px;
}

.w-80 {
    width: 80px;
}

.w-160 {
    width: 160px;
}

// height
.h-10 {
    height: 10px;
}

.h-12 {
    height: 12px;
}

.h-14 {
    height: 14px;
}
.h-18 {
    height: 18px;
}

.h-20 {
    height: 20px;
}

.h-24 {
    height: 24px;
}

.h-28 {
    height: 28px;
}

.h-30 {
    height: 30px;
}

.h-40 {
    height: 40px;
}

.h50 {
    height: 50px;
}

.h-65 {
    height: 65px;
}

.h-60 {
    height: 60px;
}

.h-70 {
    height: 70px;
}

.h-80 {
    height: 80px;
}

.h100 {
    height: 100px;
}

.h-150 {
    height: 150px !important;
}

.h-160 {
    height: 160px;
}

.h-180 {
    height: 180px;
}

.h-190 {
    height: 190px;
}

.h-200 {
    height: 200px;
}

.h-260 {
    height: 260px;
}

.h-450 {
    height: 450px;
}

// cusor point
.cursor-pointer{
    cursor: pointer;
}

// white-space-normal
.white-space-normal{
    white-space: normal;
}

// white-space-nowrap
.white-space-nowrap{
    white-space: nowrap;
}

// padding custom
.p-p5{
  padding:5px 20px;
}

.pt-pt5{
  padding:5px 0 0;
}
.pb-pb5{
  padding:0 0 5px;
}

.py-py5{
  padding:5px 0;
}

.px-px5{
  padding:0 5px;
}

.p-p24{
  padding:24px;
}

// margin custom

.m-m5{
  margin:5px 20px;
}

.mt-mt5{
  margin:5px 0 0;
}
.mb-mb5{
  margin:0 0 5px;
}

.my-my5{
  margin:5px 0;
}

.mx-mx5{
  margin:0 5px;
}

// Select Seating
.seat_checkbox{
    position: relative;
    height: 100%;
    width: 100%;
    cursor: pointer !important;
    span.seat_container{
        height: 30px;
        span.pillow{
            position: absolute;
            right: 0px;
            height: 20px;
            width:6px !important;
            margin-right: 6px;
        }
    }


    input[type="checkbox"]{
        &:checked~span.seat_container {
            border: 1px solid #0076e1 ;
            background-color: #d7e9fd;
            span{
                :first-child{
                    top: 50%;
                    left: 50%;
                    transform: translate(100%, 100%) !important;
                }
            }
            .pillow {
                border: 1px solid #0076e1 ;
                background-color: #99c9fd;
            }
        }
    }

    &.seat_checkbox_selected{
        span.seat_container {
            border: 1px solid #0076e1 ;
            background-color: #d7e9fd;
            span{
                :first-child{
                    top: 50%;
                    left: 50%;
                    transform: translate(100%, 100%) !important;
                }
            }
            .pillow {
                border: 1px solid #0076e1 ;
                background-color: #99c9fd;
            }
        }
    }

    &.seat_checkbox_disable{
        span.seat_container{
            // border: 1px solid #0076e1;
            background-color: #f3f3f3 !important;
            span.pillow {
                border: 1px solid #f3f3f3 !important;
                background-color: #e3e3e3 !important;
            }
        }
    }

    &.traveller_booked_seat{
        &.seat_checkbox_disable{
            span.seat_container{
                border: 1px solid rgb(253, 253, 146) !important;
                background-color: rgb(253, 253, 146, 0.2) !important;
                span.pillow {
                    border: 1px solid rgb(253, 253, 146) !important;
                    background-color: rgb(253, 253, 146, 0.4) !important;
                }
            }
        }
    }
    input[type="checkbox"]{
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        height: 100%;
        width: 100%;
        margin: 0;
        z-index: 1;
    }
}

// #seat-lable{
//     position: relative;
//     height: 100%;
//     width: 100%;
//     #span1{
//         height: 30px;
//         #span2{
//             height: 20px;
//             width:6px;
//             margin-right: 10px;
//         }
//     }
// }

// Custom ScrollBar 
.custom-scrollbar{
    /* width */
::-webkit-scrollbar {
  width: 10px;
  height:10px
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #c1c1c1; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #979696;
}
}

// focus 
:focus{
    outline: none;
}
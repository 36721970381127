.LoadingContainer {
    backdrop-filter: blur(4px);
    background-color: var(--background-color);
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
  
    /* filter: blur(4px); */
    z-index: 111111111;
  }
  
  .LoadingContainer1 {
    backdrop-filter: blur(1px);
    border-radius: 5px;
    opacity: 0.5;
    pointer-events: none;
  }
  
  .LoadingBox {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    position: absolute;
    top: 50%;
    width: 100%;
    z-index: 11111111111;
  }
  
  .LoadingBox1 {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 10%;
    width: 100%;
    z-index: 11111111111;
  }
  
  .Loading {
    animation: load6 1.7s infinite ease, round 1.7s infinite ease;
    border-radius: 50%;
    height: 1em;
    margin: 0.5em;
    overflow: hidden;
    text-indent: -9999em;
    transform: translateZ(0);
    width: 1em;
  }
  
  .Loading1 {
    animation: load6 1.7s infinite ease, round 1.7s infinite ease;
    border-radius: 50%;
    height: 1em;
    margin: 0.5em;
    overflow: hidden;
    text-indent: -9999em;
    transform: translateZ(0);
    width: 1em;
    color: rgb(68, 0, 255);
  }
  
  @keyframes load6 {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
        -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
        -0.297em -0.775em 0 -0.477em;
    }
  
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
        -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
        -0.749em -0.34em 0 -0.477em;
    }
  
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
        -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
        -0.82em -0.09em 0 -0.477em;
    }
  
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  
  @keyframes round {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
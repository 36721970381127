.search-user{
    .modal-content{
        height: 100% !important;
        .modal-header{
            border-bottom: 1px solid #d8d6de;
            background-color: white;
            .modal-title{
                flex-grow: 1 !important;
                .input-group{
                    box-shadow: none !important;
                }
            }
        }
        .btn-close{
            transform: none !important;
            box-shadow: none !important;
        }
    }
}

input:disabled{
    background-color: #f2f2f2 !important;
}

.seatLayout-radioContainer{
    position: relative;
    input {
        position: absolute;
        top: 0px;
        height: 100%;
        width: 100%;
        opacity: 0;
        &:checked{
            + .seatLayout-radioWrapper{
                padding: 10px 10px !important;
                border-radius: 10px;
                // border: 1px solid gray;
                box-shadow: 1px 1px 40px #00000020;
            }
        }
    }
}

.navigation{
    .nav-item{
        a {
            font-weight: 800 !important;
        }
        .menu-content{
            .nav-item{
                a {
                    font-weight: 500 !important;
                }
            }
        }
    }
}

// .react-select__multi-value__label{
//     color: #fff !important;
// }
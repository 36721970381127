// Base Extended (General styles which are not added 'base styles')
@import 'base-extended';

@import 'dark-layout';

@import 'rtl';

@import 'reactstrap/index';

@import 'app-loader';
@import 'libs/file-uploader/file-uploader.scss';
@import 'libs/react-select/react-select.scss';

.select__multi-value{
    background-color: $primary;
}